.formContainer {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 500px;
  height: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}
.login-title {
  text-align: center;
  height: 80px;
  
}