#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

#root, .ant-layout {height:100%;}
.anticon-menu-fold,.anticon-menu-unfold {padding-left: 1em;}
.sidemenu-title {font-size: 16px; text-align: center;line-height: 2;color: #fff;font-weight: 600;}